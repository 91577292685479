import React, { useEffect } from "react";
import { Button, Input, Row, Col, Tabs, Form, notification } from "antd";
import api from "src/api";
import CenterArea from "src/components/CenterArea";
import { createKey } from "src/utils/Helper";
import { useValues } from "src/utils/Hook";

const { TabPane } = Tabs;

const Detail = ({ history, match }) => {
    const [form] = Form.useForm();
    const { id } = match.params;

    const [values, setValues] = useValues({
        loading: false,
    });

    const onFinish = () => {
        form.validateFields().then((values) => {
            setValues({ loading: true });
            if (id) {
                api.put(`/domain/${id}`, values).then(({ status, message }) => {
                    setValues({ loading: false });
                    if (status) {
                        notification.success({
                            message: "Thông báo",
                            description: message || "Lưu thành công",
                        });
                        // handleBack();
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: message || "Lỗi hệ thống",
                        });
                    }
                });
            } else {
                api.post("/domain", values).then(({ status, message }) => {
                    setValues({ loading: false });
                    if (status) {
                        notification.success({
                            message: "Thông báo",
                            description: message || "Thêm thành công",
                        });
                        let key = createKey("/domain");
                        window.sessionStorage.removeItem(key);
                        handleBack();
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: message || "Lỗi hệ thống",
                        });
                    }
                });
            }
        });
    };

    const handleBack = () => {
        history.push({
            pathname: `/domain`,
        });
    };

    useEffect(() => {
        if (id) {
            api.get(`/domain/${id}`).then(({ status, data }) => {
                if (status) {
                    form.setFieldsValue({
                        domain: data.domain,
                    });
                }
            });
        } else {
            form.resetFields();
        }
    }, []);

    return (
        <Tabs defaultActiveKey={1}>
            <TabPane tab="Thông tin tên miền" key={1}>
                <CenterArea xxl={1350} xl={940} xs="100%" loading={values.loading}>
                    <Form className="small-form" form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Tên miền"
                                    name="domain"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập tên miền",
                                        },
                                    ]}
                                >
                                    <Input disabled={values.loading || !!id} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            <Button htmlType="submit" type="primary">
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

export default Detail;

import { Popconfirm } from "antd";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useRender } from "src/utils/Hook";
import "./index.scss";

class Controller {
    #id;
    #visible;
    #row;
    #x;
    #y;
    #items;
    #showCb;
    constructor() {
        this.#id = "menu-context-" + new Date().getTime();
        this.#visible = false;
        this.#row = null;
        this.#x = 0;
        this.#y = 0;
        this.#items = [];
    }
    open(row, e) {
        if (!this.#visible) {
            const onClickOutside = (e) => {
                let box = document.getElementById(this.#id);
                if (box) {
                    let confirms = box.querySelectorAll(".context-menu-confirm");
                    let is_confirm = [...confirms].find((el) => el.contains(e.target));
                    if (!is_confirm) {
                        this.#visible = false;
                        document.removeEventListener("click", onClickOutside);
                        this.#showCb();
                    }
                }
            };
            document.addEventListener("click", onClickOutside);
        }
        this.#row = row;
        this.#visible = true;
        this.#x = e.clientX;
        this.#y = e.clientY;
        this.#showCb();
    }
    onRow = (row) => {
        return {
            onContextMenu: (e) => {
                e.preventDefault();
                this.open(row, e);
            },
        };
    };
    setItems(items) {
        this.#items = items;
    }
    setOnShow(cb) {
        this.#showCb = cb;
    }
    _render() {
        if (!this.#visible || !this.#row) {
            return null;
        }
        return (
            <ul
                className="new-context-menu-popup"
                style={{
                    top: this.#visible ? this.#y : 0,
                    left: this.#visible ? this.#x : 0,
                    opacity: this.#visible ? 1 : 0,
                }}
                id={this.#id}
            >
                {this.#items
                    ?.filter((item, idx) => {
                        if (typeof item.condition === "function") {
                            return item.condition(this.#row, idx);
                        }
                        return item.condition === undefined || item.condition;
                    })
                    ?.map(
                        (
                            {
                                onClick = () => {},
                                href = null,
                                text = null,
                                icon = null,
                                confirm = false,
                                confirmTitle = "",
                                onConfirm = () => {},
                            },
                            idx
                        ) => {
                            let element = null;
                            let label = typeof text === "function" ? text(this.#row, idx) : text;
                            if (href) {
                                let url = typeof href === "function" ? href(this.#row, idx) : href;
                                element = (
                                    <Link to={url} className="context-menu-content">
                                        {icon}
                                        {label}
                                    </Link>
                                );
                            } else if (confirm) {
                                element = (
                                    <Popconfirm title={confirmTitle} onConfirm={() => onConfirm(this.#row, idx)}>
                                        <span className="context-menu-content context-menu-confirm">
                                            {icon}
                                            {label}
                                        </span>
                                    </Popconfirm>
                                );
                            } else {
                                element = (
                                    <span className="context-menu-content" onClick={() => onClick(this.#row, idx)}>
                                        {icon}
                                        {label}
                                    </span>
                                );
                            }
                            return (
                                <li key={idx} className="context-menu-item">
                                    {element}
                                </li>
                            );
                        }
                    )}
            </ul>
        );
    }
}

const useController = () => {
    return useRef(new Controller()).current;
};

const ContextMenu = ({ controller }) => {
    const [render] = useRender();

    useEffect(() => {
        if (controller instanceof Controller) {
            controller.setOnShow(() => {
                render();
            });
        }
        // eslint-disable-next-line
    }, []);

    return <>{controller._render()}</>;
};

ContextMenu.useController = useController;

export default ContextMenu;

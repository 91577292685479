export function findActiveMenu(pathname) {
    const items = [
        ["home", ["/", "/profile"]],
        ["user", ["/user", "/user/new", "/user/\\d+"]],
        ["microsoft_admin", ["/microsoft-admin", "/microsoft-admin/\\d+", "/microsoft-admin/login/\\d+"]],
        [
            "microsoft_domain",
            [
                "/microsoft-admin/domain/index",
                "/microsoft-admin/\\d+/domains",
                "/microsoft-admin/\\d+/domains/new",
                "/microsoft-admin/\\d+/domains/[a-zA-Z\\.]+",
            ],
        ],
        [
            "microsoft_user",
            [
                "/microsoft-admin/user/index",
                "/microsoft-admin/\\d+/users",
                "/microsoft-admin/\\d+/users/[a-zA-Z0-9\\-]+",
            ],
        ],
        ["domain", ["/domain", "/domain/new", "/domain/\\d+/services"]],
        ["account", ["/account", "/account/new", "/account/\\d+"]],
        // ['domain', null, [
        //     ['list_domain', '/domain'],
        // ]],
    ];
    return search(pathname, items);
}

function check(pathname, reg) {
    return new RegExp(`^${reg}$`).test(pathname);
}

function search(pathname, items, parent) {
    for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item[1] !== null) {
            if (item[1] instanceof Array) {
                for (let reg of item[1]) {
                    if (check(pathname, reg)) {
                        return {
                            active: item[0],
                            open: parent,
                        };
                    }
                }
            } else {
                if (check(pathname, item[1])) {
                    return {
                        active: item[0],
                        open: parent,
                    };
                }
            }
        } else {
            let nested = search(pathname, item[2], item[0]);
            if (nested) {
                return nested;
            }
        }
    }
}

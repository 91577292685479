import React, { useEffect, useRef } from "react";
import { Button, Input, Row, Col, Tabs, Form, Select, notification, Checkbox, message } from "antd";
import api from "src/api";
import CenterArea from "src/components/CenterArea";
import { useValues } from "src/utils/Hook";
import { removeAccents } from "src/utils";
import * as CopyToClipboard from "react-copy-to-clipboard";
import { CopyOutlined, ReloadOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const Detail = (props) => {
    const { history } = props;
    const [form] = Form.useForm();
    const [formLicences] = Form.useForm();
    const { id } = props.match.params;

    const [values, setValues] = useValues({
        loading: false,
        domains: [],
        domain: "",
    });

    const [licences, setLicences] = useValues({
        loading: false,
        skus: [],
        servicePlans: [],
        skuIds: undefined,
    });

    const loaded = useRef({
        licences: false,
    });

    const onFinish = () => {
        form.validateFields().then((values) => {
            let data = {
                email: values.email,
                display_name: values.display_name,
                password: values.password,
                domain_id: values.domain_id,
            };
            setValues({ loading: true });
            if (id) {
                api.put(`/account/${id}`, data).then(({ status, message }) => {
                    setValues({ loading: false });
                    if (status) {
                        notification.success({
                            message: "Thông báo",
                            description: message || "Lưu thành công",
                        });
                        // handleBack();
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: message || "Lỗi hệ thống",
                        });
                    }
                });
            } else {
                api.post("/account", data).then(({ status, data, message }) => {
                    setValues({ loading: false });
                    if (status) {
                        notification.success({
                            message: "Thông báo",
                            description: message || "Thêm thành công",
                        });
                        history.replace(`/account/${data.id}`);
                        // let key = createKey("/account");
                        // window.sessionStorage.removeItem(key);
                        // handleBack();
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: message || "Lỗi hệ thống",
                        });
                    }
                });
            }
        });
    };

    const handleBack = () => {
        history.push({
            pathname: `/account`,
        });
    };

    const handleChangeUsername = (username) => {
        let userName = removeAccents(username) || "";
        userName = userName.split(" ").join("");
        if (userName !== username) {
            username = userName;
            form.setFieldsValue({ username });
        }
        let email = `${username}@${values.domain}`;
        form.setFieldsValue({ email });
    };

    const handleChangeDomain = (domain_id, domains) => {
        let domain = (domains || values.domains)?.find((d) => d.id == domain_id);
        if (domain) {
            setValues({ domain: domain.text });
            const username = form.getFieldValue("username");
            const email = username + "@" + domain.text;
            if (email != form.getFieldValue("email")) {
                form.setFieldValue("email", email);
            }
        }
    };

    const handleChangeTab = (key) => {
        if (key === "license_management" && !loaded.current.licences) {
            setLicences({ loading: true });
            loaded.current.licences = true;
            api.get(`/account/${id}/subscribed-skus`)
                .then(({ status, data }) => {
                    let servicePlans = {};
                    let skuIds = [];
                    for (let skuId in data?.licences) {
                        skuIds.push(skuId);
                        servicePlans[skuId] = data.licences[skuId]?.servicePlans || [];
                    }
                    setLicences({ loading: false, skus: status ? data.skus : [], skuIds });
                    formLicences.setFieldsValue({ servicePlans, skuIds });
                })
                .catch(() => {
                    setLicences({ loading: false, skus: [] });
                });
        }
    };

    const handleSaveLicences = () => {
        formLicences
            .validateFields()
            .then((values) => {
                setLicences({ loading: true });
                let skuIds = values.skuIds || [];
                if (typeof skuIds != "object") {
                    skuIds = [];
                }
                let data = {};
                let services = values.servicePlans || {};
                skuIds.forEach((skuId) => {
                    let sku = licences.skus?.find((l) => l.skuId == skuId);
                    let servicePlans = services[skuId] || [];
                    let disabledPlans = sku.servicePlans?.map((service) => service.servicePlanId);
                    disabledPlans = disabledPlans?.filter((id) => !servicePlans?.includes(id));
                    data[skuId] = {
                        servicePlans,
                        disabledPlans,
                    };
                });
                api.put(`/account/${id}/subscribed-skus`, data)
                    .then(({ status, message }) => {
                        setLicences({ loading: false });
                        if (status) {
                            notification.success({
                                message: "Thông báo",
                                description: message || "Lưu thành công",
                            });
                        } else {
                            notification.warning({
                                message: "Thông báo",
                                description: message || "Lưu thất bại",
                            });
                        }
                    })
                    .catch(() => {
                        setLicences({ loading: false });
                        notification.warning({
                            message: "Thông báo",
                            description: "Lỗi hệ thống",
                        });
                    });
            })
            .catch(() => {});
    };

    const handleChangeLicence = (skuIds) => {
        if (!Array.isArray(skuIds) || skuIds.length == 0) {
            setLicences({ skuIds: [] });
            formLicences.setFieldsValue({ servicePlans: {} });
            return;
        }
        const skuId = skuIds[skuIds.length - 1];
        const sku = skuId ? licences.skus?.find((l) => l.skuId == skuId) : null;
        if (sku && sku.servicePlans instanceof Array) {
            const servicePlans = {
                [skuId]: sku.servicePlans.map((service) => service.servicePlanId),
            };
            formLicences.setFieldsValue({ servicePlans, skuIds: [skuId] });
            setLicences({ skuIds: [skuId] });
        } else {
            formLicences.setFieldsValue({ servicePlans: {}, skuIds: [] });
            setLicences({ skuIds: [] });
        }
        /*
        let old = licences.skuIds || [];
        if (old.length > skuIds.length) {
            let skuId = old?.find((id) => !skuIds.includes(id));
            let servicePlans = formLicences.getFieldValue("servicePlans") || {};
            delete servicePlans[skuId];
            formLicences.setFieldsValue({ servicePlans });
        }
        if (old.length < skuIds.length) {
            let skuId = skuIds?.find((id) => !old.includes(id));
            let sku = licences.skus?.find((l) => l.skuId == skuId);
            if (sku && sku.servicePlans instanceof Array) {
                let servicePlans = formLicences.getFieldValue("servicePlans") || {};
                servicePlans[skuId] = sku.servicePlans?.map((service) => service.servicePlanId);
                formLicences.setFieldsValue({ servicePlans });
            }
        }
        setLicences({ skuIds });
        */
    };

    useEffect(() => {
        const promises = [];
        promises.push(api.get(`/domain/options`, { _filter: "verified:1" }));
        if (id) {
            promises.push(api.get(`/account/${id}`));
        } else {
            form.resetFields();
        }
        setValues({ loading: true });
        Promise.all(promises).then(([domainRes, accountRes]) => {
            const domains = domainRes && domainRes.status && Array.isArray(domainRes.data) ? domainRes.data : [];
            let domain_id = null;
            if (accountRes) {
                if (accountRes.status && accountRes.data) {
                    domain_id = accountRes.data.domain_id;
                    const email = accountRes.data.email || "";
                    const username = email.split("@")[0];
                    form.setFieldsValue({
                        username,
                        display_name: accountRes.data.display_name,
                        password: accountRes.data.password,
                        email: accountRes.data.email,
                        domain_id: accountRes.data.domain_id,
                    });
                }
            } else if (domains.length > 0) {
                domain_id = domains[0].id;
                form.setFieldsValue({ domain_id });
            }
            if (domain_id) {
                handleChangeDomain(domain_id, domains);
            }
            setValues({ domains, loading: false });
        });
    }, []);

    return (
        <Tabs defaultActiveKey="general" onChange={handleChangeTab}>
            <TabPane tab="Thông tin tài khoản" key="general">
                <CenterArea xxl={1350} xl={940} xs="100%" loading={values.loading}>
                    <Form className="small-form" form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập username",
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={values.loading || values.domains.length == 0 || !!id}
                                        suffix={`@${values.domain}`}
                                        onChange={(e) => handleChangeUsername(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Tên miền"
                                    name="domain_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng chọn tên miền",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Chọn tên miền"
                                        disabled={values.loading || values.domains.length < 2}
                                        onChange={(id) => handleChangeDomain(id)}
                                    >
                                        {values.domains?.map((item, idx) => (
                                            <Select.Option key={idx} value={item.id}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} lg={24}>
                                <Form.Item label="Email" name="email">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Họ tên"
                                    name="display_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập họ tên",
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={values.loading || values.domains.length == 0}
                                        placeholder="Nhập họ tên"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Mật khẩu"
                                    name="password"
                                    rules={[
                                        {
                                            required: !id,
                                            message: "Vui lòng nhập mật khẩu",
                                        },
                                    ]}
                                >
                                    <InputPassword
                                        //
                                        disabled={values.loading || values.domains.length == 0}
                                        autoPassword={!id}
                                        placeholder={id ? "Thay đổi mật khẩu" : "Nhập mật khẩu"}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            <Button
                                                disabled={values.domains.length == 0}
                                                htmlType="submit"
                                                type="primary"
                                            >
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CenterArea>
            </TabPane>
            <TabPane tab="Quản lý giấy phép" key="license_management" disabled={!id}>
                <CenterArea xxl={1350} xl={940} xs="100%" loading={licences.loading}>
                    <Form
                        className="small-form"
                        form={formLicences}
                        onFinish={handleSaveLicences}
                        labelCol={{ span: 24 }}
                    >
                        <Form.Item label="Giấy phép" name="skuIds">
                            <Checkbox.Group onChange={handleChangeLicence} style={{ userSelect: "none" }}>
                                {licences.skus?.map((sku, idx) => (
                                    <Checkbox key={idx} value={sku.skuId}>
                                        {sku.name || sku.skuPartNumber}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                        {licences.skus?.map((sku, idx) => (
                            <React.Fragment key={idx}>
                                {licences.skuIds?.includes(sku.skuId) && (
                                    <Form.Item
                                        label={sku.name || sku.skuPartNumber}
                                        name={["servicePlans", sku.skuId]}
                                        hidden
                                    >
                                        <Checkbox.Group style={{ userSelect: "none" }}>
                                            <Row>
                                                {sku.servicePlans?.map((service, idx) => (
                                                    <Col key={idx} xs={24} md={12} lg={8}>
                                                        <Checkbox value={service.servicePlanId}>
                                                            {service.servicePlanName}
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                )}
                            </React.Fragment>
                        ))}
                        <Row>
                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            <Button htmlType="submit" type="primary">
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

function makeid(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

function InputPassword({ placeholder, value, onChange, disabled, autoPassword = false }) {
    function randomPassword() {
        let password = "";
        let count = 0;
        let check = false;
        while (count++ < 100 && !check) {
            password = makeid(8);
            check = password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/) !== null;
        }
        onChange?.(password);
    }

    useEffect(() => {
        if (autoPassword && !value) {
            randomPassword();
        }
    }, []);

    return (
        <Row gutter={4}>
            <Col flex={1}>
                <Input
                    disabled={disabled}
                    placeholder={placeholder}
                    autoComplete="new-password"
                    value={value}
                    onChange={onChange}
                />
            </Col>
            <Col>
                <Button disabled={disabled} onClick={randomPassword} icon={<ReloadOutlined />} />
            </Col>
            <Col>
                <CopyToClipboard
                    text={value}
                    onCopy={() => {
                        message.success("Đã sao chép!");
                    }}
                >
                    <Button disabled={disabled || !value} icon={<CopyOutlined />} />
                </CopyToClipboard>
            </Col>
        </Row>
    );
}

export default Detail;

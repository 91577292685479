import React from "react";
import "./center_area.scss";
import { Spin } from "antd";

const CenterArea = ({ xs, sm, md, lg, xl, xxl, name, loading = undefined, children }) => {
    let style_id = "center-area-" + name;
    let style = document.getElementById(style_id);

    if (!style) {
        style = document.createElement("style");
        style.id = style_id;
        document.querySelector("html head").append(style);
    }
    const values = { xs, sm, md, lg, xl, xxl };
    for (let key in values) {
        let value = values[key]?.toString();
        if (value) {
            if (!value.includes("%") && !value.includes("px")) {
                values[key] = value + "px";
            }
        }
    }
    style.innerHTML = `
        #${style_id} { margin: 0 auto; }
        ${values.xs ? "@media (max-width: 575px) { #" + style_id + " { width: " + values.xs + " } }" : ""}
        ${values.sm ? "@media (min-width: 576px) { #" + style_id + " { width: " + values.sm + " } }" : ""}
        ${values.md ? "@media (min-width: 768px) { #" + style_id + " { width: " + values.md + " } }" : ""}
        ${values.lg ? "@media (min-width: 992px) { #" + style_id + " { width: " + values.lg + " } }" : ""}
        ${values.xl ? "@media (min-width: 1200px) { #" + style_id + " { width: " + values.xl + " } }" : ""}
        ${values.xxl ? "@media (min-width: 1600px) { #" + style_id + " { width: " + values.xxl + " } }" : ""}
    `;

    return (
        <Spin spinning={loading || false}>
            <div id={style_id}>{children}</div>
        </Spin>
    );
};

export default CenterArea;

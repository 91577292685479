import { Modal } from "antd";

const { confirm } = Modal;

export const removeAccents = (str) => {
    if (typeof str === "string") {
        return str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/đ/g, "d")
            .replace(/Đ/g, "D");
    }
    return "";
};

export const filterAntSelectProps = {
    showSearch: true,
    optionFilterProp: "children",
    filterOption: function (input, option) {
        if (option.children) {
            let optionText = option.children;
            if (optionText instanceof Array) {
                optionText = optionText.join("");
            }
            return removeAccents(optionText).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
        }
        return false;
    },
};

export const confirmRestorseLocalStorage = (hanldeOk, handleCancel) => {
    confirm({
        title: "Gợi ý",
        content: "Gần đây bạn đã nhập form này, Bạn có muốn khôi phục form cũ đã nhập không?",
        onOk() {
            hanldeOk();
        },
        onCancel() {
            handleCancel();
        },
    });
};

export function formatMoney(n, c, d, t) {
    c = isNaN((c = Math.abs(c))) ? 0 : c;
    d = d == undefined ? "" : d;
    t = t == undefined ? "." : t;
    let s = n < 0 ? "-" : "";
    let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
    let j = i.length;
    j = j > 3 ? j % 3 : 0;

    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : "")
    );
}

export function changeStateOnline() {
    var nav = document.getElementsByClassName("am-navbar");
    if (nav[0]) {
        if (window.navigator.onLine) {
            nav[0].style.backgroundColor = "#bf0404";
        } else {
            nav[0].style.backgroundColor = "#949494";
        }
    }
}

export function getBrowser() {
    // Opera 8.0+
    var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"] || window.safari.pushNotification);

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1+
    var isChrome = !!window.chrome && !!window.chrome.webstore;

    return isOpera
        ? "Opera"
        : isFirefox
        ? "Firefox"
        : isSafari
        ? "Safari"
        : isChrome
        ? "Chrome"
        : isIE
        ? "IE"
        : isEdge
        ? "Edge"
        : "Don't know";
}

export function isNumber(input) {
    input = parseFloat(input);
    return !Number.isNaN(input) && Number.isFinite(input);
}

export function generateUuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function generateDateTime(date = new Date()) {
    return [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ].join("");
}

export function generateDate(date = new Date()) {
    return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join("");
}

export function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

export function getCurrentVersion() {
    return new Promise((resolve, reject) => {
        fetch("/index.html", { cache: "no-cache" })
            .then((res) => {
                return res.text();
            })
            .then((body) => {
                const regex = /main.(\w+).chunk.js/gm;
                const match = body.match(regex);
                if (match) {
                    const new_hash = match[0].split(".")[1];
                    resolve(new_hash);
                }
            })
            .catch(() => {
                reject(null);
            });
    });
}

export function showNewVersionPopup() {
    confirm({
        title: "Có phiên bản mới cập nhật?",
        content: "Nhấn vào nút OK để cập nhật, nhấn hủy để bỏ qua!",
        onOk() {
            window.location.reload();
        },
    });
}

export function numberWithCommas(x) {
    if (x) {
        x = x.toString().replace(/\D/g, "").replace(/\./g, "");
        return parseInt(x)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
        return "0";
    }
}

export function numberWithoutCommas(x) {
    if (x) {
        return x.toString().replace(/\./g, "");
    } else {
        return 0;
    }
}

export function copyToClipboard(str) {
    execCommandCopy(str);
}

function execCommandCopy(text_to_copy) {
    if (!navigator.clipboard) {
        const el = document.createElement("textarea"); // Create a <textarea> element
        el.value = text_to_copy; // Set its value to the string that you want copied
        el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
        el.style.position = "absolute";
        el.style.left = "-9999px"; // Move outside the screen to make it invisible
        document.body.appendChild(el); // Append the <textarea> element to the HTML document
        const selected =
            document.getSelection().rangeCount > 0 // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0) // Store selection if found
                : false; // Mark as false to know no selection existed before
        el.select(); // Select the <textarea> content
        document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el); // Remove the <textarea> element
        if (selected) {
            // If a selection existed before copying
            document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
            document.getSelection().addRange(selected); // Restore the original selection
        }
    } else {
        navigator.clipboard
            .writeText(text_to_copy)
            .then(function () {})
            .catch(function () {});
    }
}

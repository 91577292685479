import { useEffect, useMemo } from "react";
import { Table } from "antd";
import api from "src/api";
import { useFetch, useValues, useTableScroll } from "src/utils/Hook";

const TIME_TO_CHECK = 5 * 1000;

export default function TabSecond() {
    const [values, setValues] = useValues({
        pagination: { showSizeChanger: false },
        loading: false,
        dataSource: [],
    });

    const [tableRef, scrollY] = useTableScroll(0);

    const [fetchParams, fetch] = useFetch({ page: 1, slug: "" }, (fetchParams) => {
        setValues({ loading: true });
        let filters = [];
        fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
        api.get("/import_queue", {
            _page: fetchParams.page,
            _perPage: 10,
            _filter: filters?.join(";") || undefined,
            _relations: "user",
        }).then(({ status, data, meta }) => {
            if (status) {
                setValues({
                    loading: false,
                    dataSource: data,
                    pagination: {
                        showSizeChanger: values.pagination.showSizeChanger,
                        current: meta.pagination.current_page,
                        pageSize: meta.pagination.per_page,
                        total: meta.pagination.total,
                    },
                });
            } else {
                setValues({ loading: false, dataSource: [] });
            }
        });
    });

    const handleTableChange = (paginations, filters, sorters) => {
        let page = paginations.current;
        fetch({ page });
    };

    const columns = useMemo(() => {
        return [
            {
                title: "STT",
                key: "email",
                width: 50,
                render: (_, __, idx) => idx + 1 + (fetchParams.page - 1) * 10,
                align: "center",
            },
            {
                title: "Họ tên",
                dataIndex: "name",
                key: "name",
                width: 150,
                align: "center",
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
                width: 150,
                align: "center",
            },
            {
                title: "Mật khẩu",
                dataIndex: "password",
                key: "password",
                width: 100,
                align: "center",
            },
            {
                title: "Trạng thái",
                dataIndex: "status",
                key: "status",
                width: 120,
                align: "center",
                render: (status) => {
                    const text = status === "0" ? "Đang chờ" : status === "1" ? "Thành công" : "Lỗi";
                    return <>{text}</>;
                },
            },
            {
                title: "Thông báo",
                dataIndex: "errors",
                key: "errors",
                width: 120,
                align: "center",
            },
        ];
    }, [fetchParams.page]);

    const checkHasPendingRecordImported = values.dataSource.some((item) => item.status === "0");

    useEffect(() => {
        if (checkHasPendingRecordImported) {
            const intervalFn = setInterval(() => {
                fetch({}, true);
            }, TIME_TO_CHECK);

            return () => clearInterval(intervalFn);
        } else {
            fetch({}, true);
        }
    }, [checkHasPendingRecordImported]);

    return (
        <div ref={tableRef}>
            <Table
                style={{ width: "100%" }}
                rowKey="id"
                loading={values.loading}
                dataSource={values.dataSource}
                columns={columns}
                pagination={values.pagination}
                onChange={handleTableChange}
                scroll={{ y: scrollY }}
            />
        </div>
    );
}

import React, { useEffect, useRef } from "react";
import { Button, Input, Row, Col, Tabs, Form, notification, Checkbox } from "antd";
import api from "src/api";
import CenterArea from "src/components/CenterArea";
import { createKey } from "src/utils/Helper";
import { useValues } from "src/utils/Hook";

const { TabPane } = Tabs;

const Detail = (props) => {
    const { history } = props;
    const [form] = Form.useForm();
    const [formLicences] = Form.useForm();
    const { id } = props.match.params;

    const [values, setValues] = useValues({
        loading: false,
    });

    const [licences, setLicences] = useValues({
        loading: false,
        skus: [],
    });

    const loaded = useRef({
        licences: false,
    });

    const onFinish = () => {
        form.validateFields().then((values) => {
            setValues({ loading: true });
            if (id) {
                api.put(`/microsoft-admin/${id}`, values).then(({ status, message }) => {
                    setValues({ loading: false });
                    if (status) {
                        notification.success({
                            message: "Thông báo",
                            description: message || "Lưu thành công",
                        });
                        // handleBack();
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: message || "Lỗi hệ thống",
                        });
                    }
                });
            } else {
                api.post("/microsoft-admin", values).then(({ status, message }) => {
                    setValues({ loading: false });
                    if (status) {
                        let key = createKey("/microsoft-admin");
                        window.sessionStorage.removeItem(key);
                        handleBack();
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: message || "Lỗi hệ thống",
                        });
                    }
                });
            }
        });
    };

    const handleBack = () => {
        history.push({
            pathname: `/microsoft-admin`,
        });
    };

    const handleChangeTab = (key) => {
        if (key === "license_management" && !loaded.current.licences) {
            setLicences({ loading: true });
            loaded.current.licences = true;
            api.get(`/microsoft-admin/${id}/subscribed-skus`)
                .then(({ status, data }) => {
                    if (status && Array.isArray(data.skus)) {
                        formLicences.setFieldsValue({ skus: Array.isArray(data.licences) ? data.licences : [] });
                        setLicences({ loading: false, skus: data.skus });
                    } else {
                        setLicences({ loading: false, skus: [] });
                    }
                })
                .catch(() => {
                    setLicences({ loading: false, skus: [] });
                });
        }
    };

    const handleSaveLicences = () => {
        formLicences
            .validateFields()
            .then((values) => {
                setLicences({ loading: true });
                const data = { skus: Array.isArray(values.skus) ? values.skus : [] };
                api.put(`/microsoft-admin/${id}/subscribed-skus`, data)
                    .then(({ status, message }) => {
                        setLicences({ loading: false });
                        if (status) {
                            notification.success({
                                message: "Thông báo",
                                description: message || "Lưu thành công",
                            });
                        } else {
                            notification.warning({
                                message: "Thông báo",
                                description: message || "Lưu thất bại",
                            });
                        }
                    })
                    .catch(() => {
                        setLicences({ loading: false });
                        notification.warning({
                            message: "Thông báo",
                            description: "Lỗi hệ thống",
                        });
                    });
            })
            .catch(() => {});
    };

    useEffect(() => {
        if (id) {
            setValues({ loading: true });
            api.get(`/microsoft-admin/${id}`).then(({ status, data }) => {
                if (status) {
                    form.setFieldsValue({
                        name: data.name,
                        description: data.description,
                        email: data.email,
                        client_id: data.client_id,
                        tenant_id: data.tenant_id,
                        client_secret: data.client_secret,
                    });
                    setValues({ loading: false });
                }
            });
        } else {
            form.resetFields();
        }
    }, []);

    return (
        <Tabs defaultActiveKey="general" onChange={handleChangeTab}>
            <TabPane tab="Thông tin tài khoản" key="general">
                <CenterArea xxl={1350} xl={940} xs="100%" loading={values.loading}>
                    <Form className="small-form" form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Họ tên" name="name">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Email" name="email">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={24}>
                                <Form.Item label="Mô tả" name="description">
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={24}>
                                <Form.Item label="Client id" name="client_id">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={24}>
                                <Form.Item label="Tenant id" name="tenant_id">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={24}>
                                <Form.Item label="Client secret" name="client_secret">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            <Button htmlType="submit" type="primary">
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CenterArea>
            </TabPane>
            <TabPane tab="Quản lý giấy phép" key="license_management" disabled={!id}>
                <CenterArea xxl={1350} xl={940} xs="100%" loading={licences.loading}>
                    <Form
                        className="small-form"
                        form={formLicences}
                        onFinish={handleSaveLicences}
                        labelCol={{ span: 24 }}
                    >
                        <Form.Item label="Giấy phép" name="skus">
                            <Skus options={licences.skus} />
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            <Button htmlType="submit" type="primary">
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

function Skus({ value, onChange, options = [] }) {
    function handleChangeName(id, name) {
        const newValue = value ? [...value] : [];
        let item = newValue.find((o) => o.id === id);
        if (!item) {
            item = { id, active: false };
            newValue.push(item);
        }
        item.name = name;
        onChange?.(newValue);
    }

    function handleChangeActive(id, active) {
        const newValue = value ? [...value] : [];
        let item = newValue.find((o) => o.id === id);
        if (!item) {
            item = { id, name: "" };
            newValue.push(item);
        }
        item.active = active;
        onChange?.(newValue);
    }

    return (
        <>
            {options.map((sku, idx) => {
                const value_item = value?.find((o) => o.id == sku.id);
                const active = value_item?.active || false;
                const name = value_item?.name || "";

                return (
                    <Row key={sku.id} gutter={10} style={{ marginTop: idx > 0 ? 10 : undefined }}>
                        <Col style={{ lineHeight: "32px" }}>
                            <Checkbox checked={active} onChange={(e) => handleChangeActive(sku.id, e.target.checked)} />
                        </Col>
                        <Col>
                            <Input value={name} onChange={(e) => handleChangeName(sku.id, e.target.value)} />
                        </Col>
                        <Col style={{ lineHeight: "32px" }}>{sku.skuPartNumber}</Col>
                    </Row>
                );
            })}
        </>
    );
}

export default Detail;

import { Buffer } from "buffer";

const encode = (data) => {
    if (typeof data === "object") {
        data = JSON.stringify(data);
    }
    return Buffer.from(data).toString("base64");
};

const decode = (data) => {
    let value = undefined;
    try {
        value = Buffer.from(data, "base64").toString();
        return JSON.parse(value);
    } catch (e) {
    }
    return value;
};

const Base64 = { encode, decode };

export default Base64;

import { Tabs } from "antd";
import TabSecond from "./TabSecond";
import TabFirst from "./TabFirst";

export default function CustomTabs() {
    const items = [
        { label: "Tải lên", key: "tab_1", children: <TabFirst /> },
        { label: "Danh sách", key: "tab_2", children: <TabSecond /> },
    ];

    return <Tabs items={items} destroyInactiveTabPane />;
}

import store from "src/store";
import { cleanToken } from "src/store/actions/auth";
import Config from "@config";

class Api {
    #endpoint;
    constructor(endpoint) {
        this.#endpoint = endpoint.replace(/\/+$/, "");
    }
    #send = async (method, url, body, headers) => {
        const state = store.getState();
        if (state.auth.token) {
            headers.Authorization = `Bearer ${state.auth.token}`;
        }
        if (url.indexOf("http://") === -1 && url.indexOf("https://") === -1) {
            url = `${this.#endpoint}${url}`;
        }
        let json = null;
        try {
            json = JSON.parse(body);
        } catch (e) {}
        if (json) {
            headers["Content-Type"] = "application/json";
        }
        const res = await fetch(url, { method, headers, body });
        if (res?.status === 403) {
            const { dispatch } = store;
            dispatch(cleanToken());
            window.location.href = "/login";
        } else if (res?.status === 401) {
            const { dispatch } = store;
            dispatch(cleanToken());
            window.location.href = "/login";
        }

        if (headers["Content-Type"] === "application/octet-stream") {
            return res?.blob();
        }
        return res?.json() || {};
    };
    get(url, params, headers = {}) {
        let queries = [];
        for (let key in params) {
            let value = params[key];
            if (value !== undefined) {
                if (value === null) {
                    value = "null";
                }
                queries.push(`${key}=${value}`);
            }
        }
        url =
            queries.length > 0
                ? url.indexOf("?") > -1
                    ? `${url}&${queries.join("&")}`
                    : `${url}?${queries.join("&")}`
                : url;
        return this.#send("GET", url, undefined, headers);
    }
    post(url, data = {}, headers = {}) {
        let body = JSON.stringify(data);
        if (data instanceof FormData) {
            body = data;
        }
        return this.#send("POST", url, body, headers);
    }
    put(url, data = {}, headers = {}) {
        let body = JSON.stringify(data);
        return this.#send("PUT", url, body, headers);
    }
    delete(url, data = {}, headers = {}) {
        let body = JSON.stringify(data);
        return this.#send("DELETE", url, body, headers);
    }
    patch(url, data = {}, headers = {}) {
        let body = JSON.stringify(data);
        return this.#send("PATCH", url, body, headers);
    }
}

const api = new Api(Config.API_ENDPOINT);

export default api;

import { useState } from "react";
import { Button } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import api from "src/api";

function DownloadButton({ url, block = false, title = "", type = "" }) {
    const [loading, setLoading] = useState(false);

    const download = (url) => {
        if (url && typeof url === "string") {
            let filename = url.replace(/^.*[\\/]/, "");
            setLoading(true);
            api.get(url, null, {
                "Content-Type": "application/octet-stream",
            })
                .then((response) => {
                    const url = URL.createObjectURL(new Blob([response]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = filename + ".xlsx";
                    link.click();
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                });
        }
    };

    return (
        <Button onClick={() => download(url)} disabled={loading} block={block} title={title} type={type}>
            {loading ? <LoadingOutlined /> : <DownloadOutlined />}
            {title}
        </Button>
    );
}

export default DownloadButton;

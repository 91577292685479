import { Button, Col, Grid, Progress, Row, notification } from "antd";
import SelectFile from "src/components/SelectFile";
import { useValues } from "src/utils/Hook";
import DownloadButton from "src/components/DownloadButton/DownloadButton";

export default function TabFirst() {
    const [values, setValues] = useValues({
        fileList: [],
        percent: 0,
        uploading: false,
    });

    const screens = Grid.useBreakpoint();
    const currentScreen = Object.entries(screens)
        .filter((screen) => !!screen[1])
        .map((screen) => screen[0])
        .pop();

    const colSize = currentScreen === "xs" ? "100%" : "auto";

    const handleChangeFiles = (newFileList) => {
        setValues({ fileList: newFileList });
    };

    const handleImportFile = () => {
        setValues({ uploading: true });
        SelectFile.upload(values.fileList, "/import/account/import_data", (status, percent) => {
            setValues({ percent });
        })
            .then((res) => {
                if (res) {
                    notification.success({
                        message: "Thông báo",
                        description: "Import thành công",
                    });
                    setValues({ uploading: false, fileList: [] });
                } else {
                    notification.warning({
                        message: "Thông báo",
                        description: "Import thất bại",
                    });
                    setValues({ uploading: false });
                }
            })
            .catch((e) => {
                notification.warning({
                    message: "Thông báo",
                    description: "Import thất bại",
                });
                setValues({ uploading: false });
            });
    };

    const showProgressBar = values.percent !== 0 && values.percent !== 100;
    const isBtnLoadLocalFileDisabled = values.uploading;
    const isBtnUploadDisabled = values.fileList.length <= 0 || values.uploading;

    return (
        <>
            <Row gutter={[10, 10]}>
                <Col flex={colSize}>
                    <DownloadButton url="/import/account/download_sample" block title="Download file mẫu" />
                </Col>
                <Col flex={colSize}>
                    <SelectFile
                        disabled={isBtnLoadLocalFileDisabled}
                        block
                        className="account-upload"
                        fileList={values.fileList}
                        onChange={(filelist) => handleChangeFiles(filelist)}
                        selectMultiple={true}
                        accept={`
							application/vnd.ms-excel,
              				application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
          			`}
                    />
                </Col>
                <Col flex={colSize}>
                    <Button block type="primary" onClick={handleImportFile} disabled={isBtnUploadDisabled}>
                        Bắt đầu import
                    </Button>
                </Col>
            </Row>
            {showProgressBar && (
                <Row>
                    <Progress percent={values.percent} status="active" />
                </Row>
            )}
        </>
    );
}

import { Button, Col, Input, message, Row, Tabs, Form } from "antd";
import React, { useEffect } from "react";
import CenterArea from "src/components/CenterArea";
import api from "src/api";

const Profile = ({ user }) => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const onFinish = () => {
        form.validateFields().then(async (values) => {
            if (values.name.trim().length === 0) {
                message.error("Vui lòng nhập họ tên!");
            } else {
                let data = {
                    name: values.name,
                };
                api.put("/user/profile", data).then(({ status }) => {
                    if (status) {
                        message.success("Cập nhật thành công!");
                    } else {
                        message.error("Cập nhật không thành công!");
                    }
                });
            }
        });
    };

    const onChangePassword = () => {
        form2.validateFields().then(async (values) => {
            if (values.password?.trim().length === 0 || !values.password) {
                message.error("Vui lòng nhập mật khẩu hiện tại!");
            } else if (values.new_password?.trim().length === 0 || !values.new_password) {
                message.error("Vui lòng nhập mật khẩu mới!");
            }
            let data = {
                password: values.password,
                new_password: values.new_password,
            };
            api.put("/user/changepass", data).then((data) => {
                if (data.status) {
                    message.success("Cập nhật thành công!");
                } else {
                    message.error(data.message || "Cập nhật không thành công!");
                }
            });
        });
    };

    useEffect(() => {
        form.setFieldsValue({
            name: user.getName(),
            username: user.getUsername(),
        });
    }, []);

    return (
        <Tabs defaultActiveKey="general">
            <Tabs.TabPane tab="Thông tin" key="general">
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <Form className="small-form" form={form} onFinish={onFinish} labelCol={{span: 24}}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Tên đăng nhập" name="username">
                                    <Input readOnly disabled />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Họ tên" name="name">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button htmlType="submit" type="primary">
                                                Cập nhật
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CenterArea>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Đổi mật khẩu" key="security">
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <Form className="small-form" form={form2} onFinish={onChangePassword} labelCol={{span: 24}}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Mật khẩu hiện tại" name="password">
                                    <Input type="password" autoComplete="new-password" placeholder="********" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Mật khẩu mới" name="new_password">
                                    <Input type="password" autoComplete="new-password" placeholder="********" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button htmlType="submit" type="primary">
                                                Cập nhật
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CenterArea>
            </Tabs.TabPane>
        </Tabs>
    );
};

export default Profile;

import { Layout, Menu, Dropdown, Button, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import User from "../../utils/User";

function Header({ user }) {
    const menu = (
        <Menu>
            <Menu.Item key="profile">
                <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="logout" onClick={() => logOut()}>Đăng xuất</Menu.Item>
        </Menu>
    );

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("me");
        localStorage.removeItem("roles");
        window.location.href = "/login";
    };

    return (
        <>
            {user instanceof User && (
                <Layout.Header className="noPrint  layout-header">
                    <Row className="container-header">
                        <Col></Col>
                        <Col style={{ padding: "0 24px" }}>
                            Xin chào, {user.getUsername() || user.getName()}
                            <Dropdown overlay={menu}>
                                <Button icon={<UserOutlined />} shape="circle-outline" className="icon-user" />
                            </Dropdown>
                        </Col>
                    </Row>
                </Layout.Header>
            )}
        </>
    );
}

export default Header;

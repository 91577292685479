class User {
    #roles = [];
    #data = [];
    constructor(roles, data) {
        this.#roles = roles;
        this.#data = data;
    }
    getRoles() {
        return this.#roles;
    }
    hasRole(role) {
        if (!role) {
            return true;
        }
        if (role instanceof Array) {
            for (let r of role) {
                if (this.hasRole(r)) {
                    return true;
                }
            }
        } else {
            if (this.#roles === role || this.#roles.indexOf(role) > -1 || this.#roles.indexOf("ADMIN") > -1) {
                return true;
            }
        }
        return false;
    }
    getId() {
        return this.#data?.["id"] || "";
    }
    getName() {
        return this.#data?.["name"] || "";
    }
    getUsername() {
        return this.#data?.["username"] || "";
    }
    getData(key = undefined, defaultValue = undefined) {
        let data = this.#data || {};
        if (!key) {
            return data;
        }
        return data?.[key] === undefined ? defaultValue : data?.[key];
    }
}

class UserCache {
    constructor() {
        this.instance = null;
    }
}

const userCache = new UserCache();

function setCacheUser(user) {
    if (user instanceof User) {
        userCache.instance = user;
    }
}

export { userCache, setCacheUser };

export default User;

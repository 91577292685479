import { useEffect, useRef, useState } from "react";

const useTableScroll = (plus) => {
    let tableRef = useRef();
    let loaded = useRef(false);
    let [scrollY, setScrollY] = useState();

    useEffect(() => {
        let handle = () => {
            let elem = tableRef.current;
            if (elem) {
                loaded.current = true;
                setScrollY(window.innerHeight - elem.offsetTop - plus - 58);
            }
        };
        if (!loaded.current) {
            handle();
            setTimeout(() => {
                handle();
            }, 200);
        }
        window.addEventListener("resize", handle);
        return () => {
            window.removeEventListener("resize", handle);
        };
    }, []);

    return [tableRef, scrollY];
};

export default useTableScroll;

import React, { useEffect, useMemo } from "react";
import { Row, Col, Button, Input, Table, Tag } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import api from "src/api";
import { useHistory } from "react-router";
import { useFetch, useValues, useTableScroll } from "src/utils/Hook";
import { createKey } from "src/utils/Helper";
import ContextMenu from "src/components/ContextMenu";

const cacheKey = createKey("/user");

const User = ({ user }) => {
    const history = useHistory();

    const [values, setValues] = useValues({
        pagination: { showSizeChanger: false },
        loading: false,
        dataSource: [],
    });

    const [tableRef, scrollY] = useTableScroll(64);

    const contextMenuController = ContextMenu.useController();

    const [fetchParams, fetch] = useFetch(
        { page: 1, slug: "" },
        (fetchParams) => {
            setValues({ loading: true });
            let filters = [];
            fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
            api.get("/user", {
                _page: fetchParams.page,
                _perPage: 10,
                _filter: filters?.join(";") || undefined,
            }).then(({ status, data, meta }) => {
                if (status) {
                    setValues({
                        loading: false,
                        dataSource: data,
                        pagination: {
                            showSizeChanger: values.pagination.showSizeChanger,
                            current: meta.pagination.current_page,
                            pageSize: meta.pagination.per_page,
                            total: meta.pagination.total,
                        },
                    });
                } else {
                    setValues({ loading: false, dataSource: [] });
                }
            });
        },
        cacheKey
    );

    const handleSearch = (slug) => {
        fetch({ slug, page: 1 }, true);
    };

    const handleTableChange = (paginations, filters, sorters) => {
        let page = paginations.current;
        fetch({ page });
    };

    const columns = useMemo(() => {
        return [
            {
                title: "Họ tên",
                dataIndex: "name",
                key: "name",
                width: 160,
            },
            {
                title: "Tên đăng nhập",
                dataIndex: "username",
                key: "username",
                width: 160,
            },
            {
                title: "Kích hoạt",
                dataIndex: "active",
                key: "active",
                width: 100,
                render(value, row) {
                    return row.active ? "Kích hoạt" : "Khóa";
                },
            },
            {
                title: "Quyền",
                dataIndex: "roles",
                key: "roles",
                width: 200,
                render(value) {
                    return (
                        <div style={{ marginBottom: -8 }}>
                            {value?.map((role, idx) => (
                                <Tag key={idx} style={{ marginBottom: 8 }}>
                                    {role}
                                </Tag>
                            ))}
                        </div>
                    );
                },
            },
        ];
    }, []);

    useEffect(() => {
        fetch({}, true);

        contextMenuController.setItems([
            {
                text: "Cập nhật",
                icon: <EditOutlined />,
                onClick: (row) => {
                    history.push(`/user/${row.id}`);
                },
            },
            {
                text: "Xóa",
                icon: <DeleteOutlined />,
                confirm: true,
                confirmTitle: "Bạn có chắc muốn xóa?",
                condition: (row) => row.id !== user?.getId(),
                onConfirm: (row) => {
                    api.delete(`/user/${row.id}`).then(() => {
                        fetch({}, true);
                    });
                },
            },
        ]);
    }, []);

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Row gutter={10} justify="space-between" style={{ paddingBottom: 10 }}>
                        <Col>
                            <Input.Search
                                defaultValue={fetchParams.slug}
                                placeholder="Tìm kiếm ..."
                                style={{ width: 250 }}
                                onSearch={handleSearch}
                                allowClear
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Button onClick={() => history.push("/user/new")} type="primary" icon={<PlusOutlined />}>
                        Thêm mới
                    </Button>
                </Col>
            </Row>
            <div ref={tableRef}>
                <Table
                    rowKey="id"
                    loading={values.loading}
                    dataSource={values.dataSource}
                    columns={columns}
                    pagination={values.pagination}
                    onChange={handleTableChange}
                    scroll={{ y: scrollY }}
                    onRow={contextMenuController.onRow}
                />
            </div>
            <ContextMenu controller={contextMenuController} />
        </>
    );
};

export default User;

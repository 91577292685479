import { STORE_ACTION_CLEAN_TOKEN, STORE_ACTION_SET_AUTHED, STORE_ACTION_SET_TOKEN } from "../actions/auth";

const initialState = {
    authed: false,
    token: localStorage.getItem("token"),
    roles: [],
    data: [],
};

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;
    if (type === STORE_ACTION_SET_TOKEN) {
        localStorage.setItem("token", payload.token);
        return {
            ...state,
            token: payload.token,
        };
    } else if (type === STORE_ACTION_CLEAN_TOKEN) {
        localStorage.removeItem("token");
        return {
            ...state,
            token: "",
        };
    } else if (type === STORE_ACTION_SET_AUTHED) {
        return {
            ...state,
            authed: !!payload.roles,
            roles: payload.roles,
            data: payload.data,
        };
    }
    return state;
};

export default authReducer;

import { AppstoreOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import routes from ".";

const allNavs = [
    {
        key: "home",
        name: "Trang chủ",
        icon: <HomeOutlined />,
        path: "/",
    },
    {
        key: "microsoft_admin",
        name: "Tài khoản admin MS",
        icon: <AppstoreOutlined />,
        path: "/microsoft-admin",
    },
    {
        key: "user",
        name: "Tài khoản crm",
        icon: <UserOutlined />,
        path: "/user",
    },
    {
        key: "domain",
        name: "Tên miền",
        icon: <AppstoreOutlined />,
        path: "/domain",
    },
    {
        key: "account",
        name: "Tài khoản",
        icon: <UserOutlined />,
        path: "/account",
    },
    // {
    //     key: "microsoft_domain",
    //     name: "Microsoft Domain",
    //     icon: <AppstoreOutlined />,
    //     path: "/microsoft-admin/domain/index",
    // },
    // {
    //     key: "microsoft_user",
    //     name: "Microsoft User",
    //     icon: <AppstoreOutlined />,
    //     path: "/microsoft-admin/user/index",
    // },
];

export const list = (user) => {
    let navs = [];
    for (let nav of allNavs) {
        if (nav.children instanceof Array && nav.children.length > 0) {
            let children = [];
            for (let child of nav.children) {
                let route = routes.find((r) => r.path === child.path);
                if (route && user.hasRole(route.roles)) {
                    children.push({ ...child });
                }
            }
            if (children.length > 0) {
                navs.push({
                    ...nav,
                    children,
                });
            }
        } else {
            let route = routes.find((r) => r.path === nav.path);
            if (route && user.hasRole(route.roles)) {
                navs.push({ ...nav });
            }
        }
    }
    return navs;
};

import React from "react";

function PageNotFound() {
    return (
        <div style={{ textAlign: 'center', margin: 100 }}>
            <h2 style={{ fontSize: 30 }}>Oops! Page not found 404</h2>
        </div>
    );
}

export default PageNotFound;

import React, { useEffect, useMemo } from "react";
import { Row, Col, Button, Input, Table } from "antd";
import { DeleteOutlined, EditOutlined, LoginOutlined, PlusOutlined } from "@ant-design/icons";
import api from "src/api";
import { useHistory } from "react-router";
import { useFetch, useValues, useTableScroll } from "src/utils/Hook";
import { createKey } from "src/utils/Helper";
import ContextMenu from "src/components/ContextMenu";

const cacheKey = createKey("/microsoft-admin");

const MicrosoftAdmin = () => {
    const history = useHistory();

    const [values, setValues] = useValues({
        pagination: { showSizeChanger: false },
        loading: false,
        dataSource: [],
    });

    const [tableRef, scrollY] = useTableScroll(64);

    const contextMenuController = ContextMenu.useController();

    const [fetchParams, fetch] = useFetch(
        { page: 1, slug: "" },
        (fetchParams) => {
            setValues({ loading: true });
            let filters = [];
            fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
            api.get("/microsoft-admin", {
                _page: fetchParams.page,
                _perPage: 10,
                _filter: filters?.join(";") || undefined,
            }).then(({ status, data, meta }) => {
                if (status) {
                    setValues({
                        loading: false,
                        dataSource: data,
                        pagination: {
                            showSizeChanger: values.pagination.showSizeChanger,
                            current: meta.pagination.current_page,
                            pageSize: meta.pagination.per_page,
                            total: meta.pagination.total,
                        },
                    });
                } else {
                    setValues({ loading: false, dataSource: [] });
                }
            });
        },
        cacheKey
    );

    const handleSearch = (slug) => {
        fetch({ slug, page: 1 }, true);
    };

    const handleTableChange = (paginations, filters, sorters) => {
        let page = paginations.current;
        fetch({ page });
    };

    const columns = useMemo(() => {
        return [
            {
                title: "Họ tên",
                dataIndex: "name",
                key: "name",
                width: 160,
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
                width: 250,
            },
            {
                title: "Mô tả",
                dataIndex: "description",
                key: "description",
                width: 350,
                render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
            },
            {
                title: "Client id",
                dataIndex: "client_id",
                key: "client_id",
                width: 330,
            },
            {
                title: "Tenant id",
                dataIndex: "tenant_id",
                key: "tenant_id",
                width: 330,
            },
        ];
    }, []);

    useEffect(() => {
        fetch({}, true);

        contextMenuController.setItems([
            {
                text: "Cập nhật",
                icon: <EditOutlined />,
                onClick: (row) => {
                    history.push(`/microsoft-admin/${row.id}`);
                },
            },
            {
                text: "Đăng nhập",
                icon: <LoginOutlined />,
                onClick: (row) => {
                    history.push(`/microsoft-admin/login/${row.id}`);
                },
            },
            // {
            //     text: "Danh sách tên miền",
            //     icon: <UnorderedListOutlined />,
            //     href: (row) => {
            //         return `/microsoft-admin/${row.id}/domains`;
            //     },
            // },
            // {
            //     text: "Danh sách tài khoản",
            //     icon: <UnorderedListOutlined />,
            //     href: (row) => {
            //         return `/microsoft-admin/${row.id}/users`;
            //     },
            // },
            {
                text: "Xóa",
                icon: <DeleteOutlined />,
                confirm: true,
                confirmTitle: "Bạn có chắc muốn xóa?",
                onConfirm: (row) => {
                    api.delete(`/microsoft-admin/${row.id}`).then(() => {
                        fetch({}, true);
                    });
                },
            },
        ]);
    }, []);

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Row gutter={10} justify="space-between" style={{ paddingBottom: 10 }}>
                        <Col>
                            <Input.Search
                                defaultValue={fetchParams.slug}
                                placeholder="Tìm kiếm ..."
                                style={{ width: 250 }}
                                onSearch={handleSearch}
                                allowClear
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Button onClick={() => history.push("/microsoft-admin/new")} type="primary" icon={<PlusOutlined />}>
                        Thêm mới
                    </Button>
                </Col>
            </Row>
            <div ref={tableRef}>
                <Table
                    rowKey="id"
                    loading={values.loading}
                    dataSource={values.dataSource}
                    columns={columns}
                    pagination={values.pagination}
                    onChange={handleTableChange}
                    scroll={{ y: scrollY }}
                    onRow={contextMenuController.onRow}
                />
            </div>
            <ContextMenu controller={contextMenuController} />
        </>
    );
};

export default MicrosoftAdmin;

import { useReducer } from "react";

const useValues = (initialValues = {}) => {
    const keys = Object.keys(initialValues);
    const [values, dispatch] = useReducer((oldValues, newValues) => {
        for (let key in newValues) {
            if (keys?.includes(key)) {
                oldValues[key] = newValues[key];
            }
        }
        return { ...oldValues };
    }, initialValues);
    const setValues = (vals) => {
        dispatch(vals);
    };
    return [values, setValues];
};

export default useValues;

import { useReducer } from "react";

const useRender = () => {
    const [, _render] = useReducer((p) => !p, false);
    const render = () => {
        setTimeout(() => {
            _render();
        });
    };
    return [render];
};

export default useRender;

import React, { useEffect, useMemo } from "react";
import { Row, Col, Button, Input, Table, notification, Grid } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import api from "src/api";
import { useFetch, useValues, useTableScroll } from "src/utils/Hook";
import { createKey } from "src/utils/Helper";
import ContextMenu from "src/components/ContextMenu";
import CustomModal from "./Components/CustomModal";
import DownloadButton from "src/components/DownloadButton/DownloadButton";

const cacheKey = createKey("/account");

const Account = ({ history, user }) => {
    const [values, setValues] = useValues({
        pagination: { showSizeChanger: false },
        loading: false,
        dataSource: [],
    });

    const screens = Grid.useBreakpoint();
    const currentScreen = Object.entries(screens)
        .filter((screen) => !!screen[1])
        .map((screen) => screen[0])
        .pop();

    const [tableRef, scrollY] = useTableScroll(64);

    const contextMenuController = ContextMenu.useController();

    const [fetchParams, fetch] = useFetch(
        { page: 1, slug: "" },
        (fetchParams) => {
            setValues({ loading: true });
            let filters = [];
            fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
            api.get("/account", {
                _page: fetchParams.page,
                _perPage: 10,
                _filter: filters?.join(";") || undefined,
                _relations: "user",
            }).then(({ status, data, meta }) => {
                if (status) {
                    setValues({
                        loading: false,
                        dataSource: data,
                        pagination: {
                            showSizeChanger: values.pagination.showSizeChanger,
                            current: meta.pagination.current_page,
                            pageSize: meta.pagination.per_page,
                            total: meta.pagination.total,
                        },
                    });
                } else {
                    setValues({ loading: false, dataSource: [] });
                }
            });
        },
        cacheKey
    );

    const handleSearch = (slug) => {
        fetch({ slug, page: 1 }, true);
    };

    const handleTableChange = (paginations, filters, sorters) => {
        let page = paginations.current;
        fetch({ page });
    };

    const columns = useMemo(() => {
        return [
            {
                title: "STT",
                key: "email",
                width: 60,
                render: (_, __, idx) => idx + 1 + (fetchParams.page - 1) * 10,
                align: "center",
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
                width: 300,
            },
            {
                title: "Họ tên",
                dataIndex: "display_name",
                key: "display_name",
                width: 260,
            },
            {
                title: "Người tạo",
                dataIndex: ["user", "name"],
                key: "user",
                width: 300,
            },
        ];
    }, [fetchParams.page]);

    useEffect(() => {
        fetch({}, true);

        contextMenuController.setItems([
            {
                text: "Cập nhật",
                icon: <EditOutlined />,
                onClick: (row) => {
                    history.push(`/account/${row.id}`);
                },
            },
            {
                text: "Xóa",
                icon: <DeleteOutlined />,
                confirm: true,
                confirmTitle: "Bạn có chắc muốn xóa?",
                onConfirm: (row) => {
                    setValues({ loading: true });
                    api.delete(`/account/${row.id}`)
                        .then(({ status, message }) => {
                            if (status) {
                                notification.success({
                                    message: "Thông báo",
                                    description: message || "Xóa thành công",
                                });
                                fetch({}, true);
                            }
                        })
                        .catch(() => {
                            setValues({ loading: false });
                        });
                },
            },
        ]);
    }, []);

    const isBtnDisabled =
        (!user.hasRole("ADMIN") && user.getData("limit_account") <= (values.pagination.total || 0)) ||
        values.pagination.total === undefined;

    const buttonSize = currentScreen === "xs" ? "100%" : "auto";
    const urlExport = fetchParams.slug ? `/export/account?slug=${fetchParams.slug}` : "/export/account";

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Row gutter={10} justify="space-between" style={{ paddingBottom: 10 }}>
                        <Col>
                            <Input.Search
                                defaultValue={fetchParams.slug}
                                placeholder="Tìm kiếm ..."
                                style={{ width: 250 }}
                                onSearch={handleSearch}
                                allowClear
                            />
                        </Col>
                        {!user.hasRole("ADMIN") && (
                            <Col style={{ lineHeight: "32px" }}>
                                {values.pagination?.total}/{user.getData("limit_account")}
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col>
                    <Row gutter={[10, 10]} justify={{ md: "end" }}>
                        <Col flex={buttonSize}>
                            <CustomModal
                                triggerBtnFullWidth={currentScreen === "xs" ?? false}
                                disabled={isBtnDisabled}
                            />
                        </Col>
                        <Col flex={buttonSize}>
                            <DownloadButton url={urlExport} block title="Export" type="primary" />
                        </Col>
                        <Col flex={buttonSize}>
                            <Button
                                block={currentScreen === "xs" ?? false}
                                onClick={() => history.push("/account/new")}
                                type="primary"
                                icon={<PlusOutlined />}
                                disabled={isBtnDisabled}
                            >
                                Thêm mới
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div ref={tableRef}>
                <Table
                    rowKey="id"
                    loading={values.loading}
                    dataSource={values.dataSource}
                    columns={columns}
                    pagination={values.pagination}
                    onChange={handleTableChange}
                    scroll={{ y: scrollY }}
                    onRow={contextMenuController.onRow}
                />
            </div>
            <ContextMenu controller={contextMenuController} />
        </>
    );
};

export default Account;

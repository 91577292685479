import { Button, Input, notification } from "antd";
import api from "src/api";
import { useValues } from "src/utils/Hook";

const MicrosoftAdminLogin = ({ history, match }) => {
    const { id } = match.params;

    const [values, setValues] = useValues({
        code: "",
        loading: false,
    });

    const loginMicrosoft = () => {
        setValues({ loading: true });
        api.get(`/microsoft-admin/${id}/get-login-url`).then(({ status, url }) => {
            setValues({ loading: false });
            if (status && url) {
                window.open(url, "_blank");
            }
        });
    };

    const submit = () => {
        setValues({ loading: true });
        api.post(`/microsoft-admin/${id}/login`, { code: values.code }).then(({ status, url }) => {
            setValues({ loading: false });
            if (status) {
                notification.success({
                    message: "Thông báo",
                    description: "Đăng nhập thành công.",
                });
                history.push({
                    pathname: `/microsoft-admin`,
                });
            } else {
                notification.error({
                    message: "Thông báo",
                    description: "Đăng nhập thất bại.",
                });
            }
        });
    };

    return (
        <>
            <Button loading={values.loading} onClick={loginMicrosoft} style={{ marginBottom: 10 }} type="primary">
                Login Microsoft
            </Button>
            <Input.TextArea
                disabled={values.loading}
                value={values.code}
                onChange={(e) => setValues({ code: e.target.value })}
                style={{ marginBottom: 10 }}
                placeholder="Code"
                rows={5}
            />
            <Button loading={values.loading} onClick={submit} type="primary">
                Submit
            </Button>
        </>
    );
};

export default MicrosoftAdminLogin;

import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import api from "src/api";

function SelectFile({
    disabled = false,
    fileList = undefined,
    onChange = () => {},
    multiple = true,
    selectMultiple = false,
    listType = "text",
    className = undefined,
    accept = undefined,
    itemRender = undefined,
    maxCount = undefined,
    block = false,
}) {
    function handleChange(info) {
        let _fileList = [];
        if (multiple) {
            if (info.file.isDelete) {
                _fileList = [...info.fileList];
            } else {
                _fileList = [...info.fileList.filter((file) => file.name != info.file.name), info.file];
            }
        } else {
            _fileList = info.fileList.length > 0 ? [info.fileList[info.fileList.length - 1]] : [];
        }
        switch (info.file.status) {
            case "uploading":
                onChange(_fileList);
                break;
            case "done":
                onChange(_fileList);
                break;
            default:
                break;
        }
    }

    function handleRemove(file) {
        file.isDelete = true;
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        onChange(newFileList);
    }

    return (
        <Upload
            className={className}
            fileList={fileList}
            onChange={handleChange}
            customRequest={({ onSuccess }) => {
                setTimeout(() => onSuccess("ok"), 0);
            }}
            onRemove={handleRemove}
            multiple={selectMultiple}
            listType={listType}
            accept={accept}
            itemRender={itemRender}
            maxCount={maxCount}
            disabled={disabled}
        >
            <Button block={block} disabled={disabled}>
                <UploadOutlined /> Chọn file
            </Button>
        </Upload>
    );
}

SelectFile.upload = (fileList, endpoint, onProgress = (status, percent) => {}) => {
    return new Promise((resolve, reject) => {
        const arr = [];
        const files = [];
        if (Array.isArray(fileList) && fileList.length > 0) {
            fileList.forEach((file) => {
                if (file.originFileObj) {
                    arr.push(file.originFileObj);
                } else {
                    files.push({
                        uid: file.uid,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        url: file.url,
                    });
                }
            });
            if (arr.length > 0) {
                const formData = new FormData();
                arr.forEach((file, idx) => {
                    formData.append("file-" + idx, file);
                });
                api.post(endpoint, formData).then(({ status, data }) => {
                    if (status) {
                        arr.forEach((file, idx) => {
                            files.push({
                                uid: file.uid,
                                name: file.name,
                                type: file.type,
                                size: file.size,
                                url: data?.["file-" + idx] || null,
                            });
                        });
                        onProgress("done", 100);
                        resolve(files);
                    }
                });
            } else {
                resolve(files);
            }
        } else {
            resolve([]);
        }
    });
};

export default SelectFile;

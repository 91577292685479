import { useRef } from "react";
import Base64 from "src/utils/Encrypt/base64";

const cache = (cacheKey, fetchParams, storage) => {
    if (!cacheKey) {
        return;
    }
    if (!storage) {
        storage = window.sessionStorage;
    }
    storage.setItem(cacheKey, Base64.encode(Base64.encode(fetchParams)));
};

const loadCached = (cacheKey, storage, defaultParams) => {
    if (!cacheKey) {
        return defaultParams;
    }
    if (!storage) {
        storage = window.sessionStorage;
    }
    let cached = Base64.decode(Base64.decode(storage.getItem(cacheKey)));
    let result = {};
    for (let key in defaultParams) {
        result[key] = cached?.[key] || defaultParams[key];
    }
    return result;
};

const useFetch = (defaultParams = {}, cb = () => {}, cacheKey = undefined, storage = undefined) => {
    const fetchParams = useRef(loadCached(cacheKey, storage, defaultParams));
    const fetch = (values = {}, reload = false) => {
        for (let key in values) {
            if (fetchParams.current[key] !== values[key]) {
                reload = true;
                fetchParams.current[key] = values[key];
            }
        }
        if (reload) {
            cache(cacheKey, fetchParams.current, storage);
            return cb(fetchParams.current);
        }
    };
    const refetch = () => {
        return fetch({ ...defaultParams }, true);
    };
    return [fetchParams.current, fetch, refetch];
};

export default useFetch;

import React from "react";

function Forbidden() {
    return (
        <div style={{ textAlign: 'center', margin: 100 }}>
            <h2 style={{ fontSize: 30 }}>Forbidden 403</h2>
        </div>
    );
}

export default Forbidden;

import Account from "src/containers/Account";
import AccountDetail from "src/containers/Account/Detail";
import Domain from "src/containers/Domain";
import DomainDetail from "src/containers/Domain/Detail";
import DomainServices from "src/containers/Domain/Services";
import Home from "src/containers/Home";
import MicrosoftAdmin from "src/containers/MicrosoftAdmin";
import MicrosoftAdminDetail from "src/containers/MicrosoftAdmin/Detail";
import MicrosoftAdminLogin from "src/containers/MicrosoftAdmin/MicrosoftAdminLogin";
// import MicrosoftDomain from "src/containers/MicrosoftDomain";
// import MicrosoftDomainDetail from "src/containers/MicrosoftDomain/Detail";
// import SelectMicrosoftAdmin from "src/containers/MicrosoftDomain/SelectMicrosoftAdmin";
// import MicrosoftUser from "src/containers/MicrosoftUser";
// import MicrosoftUserDetail from "src/containers/MicrosoftUser/Detail";
// import MicrosoftUserSelectMicrosoftAdmin from "src/containers/MicrosoftUser/MicrosoftUserSelectMicrosoftAdmin";
import Profile from "src/containers/Profile";
import User from "src/containers/User";
import UserDetail from "src/containers/User/Detail";

const routes = [
    {
        path: "/",
        component: Home,
        exact: true,
    },
    {
        path: "/profile",
        component: Profile,
        exact: true,
    },
    {
        path: "/user",
        component: User,
        exact: true,
        roles: "ADMIN",
    },
    {
        path: "/user/new",
        component: UserDetail,
        exact: true,
        roles: "ADMIN",
    },
    {
        path: "/user/:id",
        component: UserDetail,
        exact: true,
        roles: "ADMIN",
    },
    {
        path: "/microsoft-admin",
        component: MicrosoftAdmin,
        exact: true,
        roles: "ADMIN",
    },
    {
        path: "/microsoft-admin/new",
        component: MicrosoftAdminDetail,
        exact: true,
        roles: "ADMIN",
    },
    {
        path: "/microsoft-admin/:id",
        component: MicrosoftAdminDetail,
        exact: true,
        roles: "ADMIN",
    },
    {
        path: "/microsoft-admin/login/:id",
        component: MicrosoftAdminLogin,
        exact: true,
        roles: "ADMIN",
    },
    // {
    //     path: "/microsoft-admin/domain/index",
    //     component: SelectMicrosoftAdmin,
    //     exact: true,
    //     roles: "ADMIN",
    // },
    // {
    //     path: "/microsoft-admin/:admin_id/domains",
    //     component: MicrosoftDomain,
    //     exact: true,
    //     roles: "ADMIN",
    // },
    // {
    //     path: "/microsoft-admin/:admin_id/domains/new",
    //     component: MicrosoftDomainDetail,
    //     exact: true,
    //     roles: "ADMIN",
    // },
    // {
    //     path: "/microsoft-admin/:admin_id/domains/:id",
    //     component: MicrosoftDomainDetail,
    //     exact: true,
    //     roles: "ADMIN",
    // },
    // {
    //     path: "/microsoft-admin/user/index",
    //     component: MicrosoftUserSelectMicrosoftAdmin,
    //     exact: true,
    //     roles: "ADMIN",
    // },
    // {
    //     path: "/microsoft-admin/:admin_id/users",
    //     component: MicrosoftUser,
    //     exact: true,
    //     roles: "ADMIN",
    // },
    // {
    //     path: "/microsoft-admin/:admin_id/users/new",
    //     component: MicrosoftUserDetail,
    //     exact: true,
    //     roles: "ADMIN",
    // },
    // {
    //     path: "/microsoft-admin/:admin_id/users/:id",
    //     component: MicrosoftUserDetail,
    //     exact: true,
    //     roles: "ADMIN",
    // },
    {
        path: "/domain",
        component: Domain,
        exact: true,
        roles: "CUSTOMER",
    },
    {
        path: "/domain/new",
        component: DomainDetail,
        exact: true,
        roles: "CUSTOMER",
    },
    {
        path: "/domain/:id",
        component: DomainDetail,
        exact: true,
        roles: "CUSTOMER",
    },
    {
        path: "/domain/:id/services",
        component: DomainServices,
        exact: true,
        roles: "CUSTOMER",
    },
    {
        path: "/account",
        component: Account,
        exact: true,
        roles: "CUSTOMER",
    },
    {
        path: "/account/new",
        component: AccountDetail,
        exact: true,
        roles: "CUSTOMER",
    },
    {
        path: "/account/:id",
        component: AccountDetail,
        exact: true,
        roles: "CUSTOMER",
    },
];

export default routes;

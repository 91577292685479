import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { Layout, Menu, notification } from "antd";
import { Link } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { findActiveMenu } from "src/routes/helpers";
import { list } from "src/routes/navs";
import { createKey } from "src/utils/Helper";
import { useValues } from "src/utils/Hook";

function Sidebar({ history, user }) {
    const [values, setValues] = useValues({
        collapsed: localStorage.getItem("collapsed") == 1,
        openKeys: [],
        activeKeys: "",
    });

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
    }, []);

    const clickMenuItem = (url) => {
        let key = createKey(url);
        window.sessionStorage.removeItem(key);
    };

    const navs = useMemo(() => {
        return list(user);
    }, [user]);

    const menu = useMemo(() => {
        let _menuProcess = (items) => {
            return (
                Array.isArray(items) &&
                items.map((item) => {
                    if (item.children && item.children.length > 0) {
                        let menu = _menuProcess(item.children);
                        return (
                            <Menu.SubMenu
                                key={"sub" + item.key}
                                title={
                                    <span>
                                        {item.icon}
                                        <span className="nav-text">{item.name}</span>
                                    </span>
                                }
                            >
                                {menu}
                            </Menu.SubMenu>
                        );
                    } else {
                        return item.path ? (
                            <Menu.Item key={"menu" + item.key}>
                                <Link to={item.path} onClick={() => clickMenuItem(item.path)}>
                                    {item.icon}
                                    <span>{item.name}</span>
                                </Link>
                            </Menu.Item>
                        ) : (
                            <Menu.Item key={"menu" + item.key}>
                                {item.icon}
                                <span>{item.name}</span>
                            </Menu.Item>
                        );
                    }
                })
            );
        };
        return _menuProcess(navs);
    }, [navs, user]);

    useEffect(() => {
        if (user) {
            activeMenu(window.location.pathname);
            history.listen((location) => {
                activeMenu(location.pathname);
            });
        }
        // eslint-disable-next-line
    }, [user]);

    const toggle = () => {
        localStorage.setItem("collapsed", values.collapsed ? 0 : 1);
        setValues({ collapsed: !values.collapsed });
    };

    const activeMenu = function (pathname) {
        let data = findActiveMenu(pathname);
        if (data) {
            setValues({
                activeKeys: data.active ? [`menu${data.active}`] : [],
                openKeys: data.open ? [`sub${data.open}`] : [],
            });
        }
    };

    return (
        <Layout.Sider trigger={null} collapsible collapsed={values.collapsed} className="noPrint">
            <div className="logo">
                {React.createElement(values.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: "trigger",
                    onClick: toggle,
                })}
                <div className="ant-layout-logo">{!values.collapsed ? "Microsoft Account" : ""}</div>
            </div>
            <Menu
                selectedKeys={values.activeKeys}
                openKeys={values.openKeys}
                theme="dark"
                mode="inline"
                siderCollapsed={values.collapsed}
                onOpenChange={(keys) => {
                    const latestOpenKey = keys.find((key) => values.openKeys.indexOf(key) === -1);
                    setValues({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
                }}
            >
                {menu}
            </Menu>
        </Layout.Sider>
    );
}

export default withRouter(Sidebar);

import React, { useEffect } from "react";
import { Button, Row, Col, Tabs, Form, notification, Checkbox, Tag } from "antd";
import api from "src/api";
import CenterArea from "src/components/CenterArea";
import { useValues } from "src/utils/Hook";

import "./service.scss";

const { TabPane } = Tabs;

const services = [
    "Email",
    // "Sharepoint",
    "EmailInternalRelayOnly",
    "OfficeCommunicationsOnline",
    "SharepointDefaultDomain",
    // "FullRedelegation",
    "SharePointPublic",
    "OrgIdAuthentication",
    "Yammer",
    "Intune",
];

const Services = ({ history, match, user }) => {
    const [form] = Form.useForm();
    const { id } = match.params;

    const [values, setValues] = useValues({
        loading: false,
        services: [],
    });

    const onFinish = () => {
        form.validateFields().then((values) => {
            let data = {
                services: values.services || [],
            };
            setValues({ loading: true });
            api.post(`/domain/${id}/services`, data)
                .then(({ status }) => {
                    setValues({ loading: false });
                    if (status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Lưu thành công",
                        });
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: "Lỗi hệ thống",
                        });
                    }
                })
                .catch(() => {
                    setValues({ loading: false });
                    notification.warning({
                        message: "Thông báo",
                        description: "Lỗi hệ thống",
                    });
                });
        });
    };

    const handleBack = () => {
        history.push({
            pathname: `/domain`,
        });
    };

    const renderItem = (item) => {
        if (item.type === "Mx") {
            return (
                <div style={{ display: "flex" }}>
                    <span style={{ display: "inline-block", width: 80 }}>Priority: </span>{" "}
                    <Tag style={{ marginBottom: 5 }}>{item.preference}</Tag>
                </div>
            );
        }
        if (item.type === "Srv") {
            return (
                <>
                    <div style={{ display: "flex" }}>
                        <span style={{ display: "inline-block", width: 80 }}>Priority: </span>{" "}
                        <Tag style={{ marginBottom: 5 }}>{item.priority}</Tag>
                    </div>
                    <div style={{ display: "flex" }}>
                        <span style={{ display: "inline-block", width: 80 }}>Protocol: </span>{" "}
                        <Tag style={{ marginBottom: 5 }}>{item.protocol}</Tag>
                    </div>
                    <div style={{ display: "flex" }}>
                        <span style={{ display: "inline-block", width: 80 }}>Port: </span>{" "}
                        <Tag style={{ marginBottom: 5 }}>{item.port}</Tag>
                    </div>
                    <div style={{ display: "flex" }}>
                        <span style={{ display: "inline-block", width: 80 }}>Weight: </span>{" "}
                        <Tag style={{ marginBottom: 5 }}>{item.weight}</Tag>
                    </div>
                </>
            );
        }
    };

    useEffect(() => {
        if (id) {
            setValues({ loading: true });
            api.get(`/domain/${id}/services`).then(({ status, data }) => {
                if (status) {
                    form.setFieldsValue({
                        services: data.services || [],
                    });
                    setValues({ loading: false });
                }
            });
            api.get(`/domain/${id}/list-service-configuration-records`).then(({ status, data }) => {
                if (status) {
                    setValues({ services: status ? data : [] });
                }
            });
        } else {
            form.resetFields();
        }
    }, []);

    return (
        <Tabs defaultActiveKey={1}>
            <TabPane tab="Thông tin tên miền" key={1}>
                <CenterArea xxl={1350} xl={940} xs="100%" loading={values.loading}>
                    <Form className="small-form" form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
                        <Row gutter={16}>
                            <Col xs={24} lg={24}>
                                <Form.Item label="Dịch vụ" name="services">
                                    <Checkbox.Group disabled={values.loading}>
                                        <Row>
                                            {services?.map((service) => (
                                                <Col key={service} lg={8} xs={24}>
                                                    <Checkbox value={service}>{service}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            <Button htmlType="submit" type="primary">
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <div style={{ overflowX: "auto" }}>
                        {values.services?.length > 0 && (
                            <table className="service-dns-records" border="1px" width="100%">
                                <thead>
                                    <tr>
                                        <td>Dịch vụ</td>
                                        <td>Loại</td>
                                        <td>Thiết lập</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.services?.map((service, idx) => (
                                        <React.Fragment key={idx}>
                                            <tr>
                                                <td rowSpan={service?.records?.length + 1}>{service.service}</td>
                                            </tr>
                                            {service?.records?.map((record, i) => (
                                                <tr key={i}>
                                                    <td style={{ textTransform: "uppercase" }}>
                                                        <Tag>{record.type}</Tag>
                                                    </td>
                                                    <td>
                                                        <span style={{ display: "inline-block", width: 80 }}>
                                                            Name:{" "}
                                                        </span>
                                                        <Tag style={{ marginBottom: 5 }}>{record.name}</Tag>
                                                        <br />
                                                        <span style={{ display: "inline-block", width: 80 }}>
                                                            Value:{" "}
                                                        </span>
                                                        <Tag style={{ marginBottom: 5 }}>{record.value}</Tag>
                                                        <br />
                                                        <span style={{ display: "inline-block", width: 80 }}>
                                                            Ttl:{" "}
                                                        </span>
                                                        <Tag style={{ marginBottom: 5 }}>{record.ttl}</Tag>
                                                        {renderItem(record)}
                                                    </td>
                                                    <td>{record.isOptional ? "Tùy chọn" : "Bắt buộc"}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

export default Services;

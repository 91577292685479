import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Button, Row, Col, message, Modal, Form, notification } from "antd";
import { withRouter } from "react-router-dom";
import { setToken } from "src/store/actions/auth";
import { useDispatch } from "react-redux";
import { getBrowser } from "src/utils";
import api from "src/api";
import "./login.scss";
import md5 from "md5";
import { useValues } from "src/utils/Hook";

let propTypes = {
    user: PropTypes.object,
    loggingIn: PropTypes.bool,
    loginErrors: PropTypes.string,
};

const Login = ({ history }) => {
    const [values, setValues] = useValues({
        loading: false,
        confirmLoading: false,
        visibleResetPass: false,
        emailReset: null,
    });
    const [form] = Form.useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 3,
        });
    }, []);

    const handleSubmit = () => {
        setValues({ loading: true });
        form.validateFields().then((values) => {
            let data = values;
            if (data.remember) {
                localStorage.setItem("rem", data.remember);
                localStorage.setItem("username", data.username);
            }

            api.post("/user/login", {
                username: data.username,
                password: data.password,
                device_id: md5(data.email + getBrowser() + Math.floor(Math.random() * 100000000)),
            })
                .then(({ status, token, user }) => {
                    setValues({ loading: false });
                    if (status && token) {
                        dispatch(setToken(token));
                        message.success("Welcome " + user?.username);
                        history.replace("/");
                        // if (res.user.is_admin) {
                        //     history.replace("/");
                        // } else {
                        //     history.replace("/student");
                        // }
                    } else {
                        message.error("Đăng nhập không thành công");
                    }
                })
                .catch(() => {
                    setValues({ loading: false });
                    message.error("Đăng nhập không thành công");
                });
        });
    };

    const handleResetPass = () => {
        if (values.emailReset) {
            setValues({ confirmLoading: true });
            api.put("/user/resetpass", {
                email: values.emailReset,
            }).then(
                (res) => {
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Kiểm tra email để nhận mật khẩu mới!",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message,
                        });
                    }
                    setValues({ confirmLoading: false, visibleResetPass: false });
                },
                () => {
                    setValues({ confirmLoading: false });
                }
            );
        } else {
            notification.error({
                message: "Cảnh báo",
                description: "Vui lòng nhập email",
            });
        }
    };

    return (
        <>
            <div className="bg-login">
                <div className="login-form" justify="space-around" align="middle">
                    <Form
                        className="small-form"
                        form={form}
                        onFinish={handleSubmit}
                        initialValues={{
                            remember: localStorage.getItem("rem") ? localStorage.getItem("rem") === "true" : false,
                        }}
                        labelCol={{ span: 24 }}
                    >
                        <h2 className="logo" style={{ textAlign: "center" }}>
                            <span>MS Account Manager</span>
                        </h2>
                        <Form.Item
                            label="Tên đăng nhập"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập username",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Mật khẩu"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập mật khẩu",
                                },
                            ]}
                        >
                            <Input type="password" />
                        </Form.Item>
                        {/* <Form.Item name="remember" valuePropName="checked">
                            <Row justify="space-between">
                                <Checkbox className="user-select-none">Ghi nhớ</Checkbox>
                                <Link className="login-form-forgot" to="/">
                                    Quên mật khẩu
                                </Link>
                            </Row>
                        </Form.Item> */}
                        <Row justify="end">
                            <Col>
                                <Button
                                    className="btn-login"
                                    type="primary"
                                    size="large"
                                    loading={values.loading}
                                    htmlType="submit"
                                >
                                    Đăng nhập
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Modal
                        title="Reset Password"
                        visible={values.visibleResetPass}
                        onOk={handleResetPass}
                        confirmLoading={values.confirmLoading}
                        onCancel={() => setValues({ visibleResetPass: false })}
                    >
                        <Input
                            placeholder="Email đã đăng ký"
                            value={values.emailReset}
                            onChange={(e) => setValues({ emailReset: e.target.value })}
                        />
                    </Modal>
                </div>
            </div>
        </>
    );
};

Login.propTypes = propTypes;

export default withRouter(Login);

import React, { useEffect, useMemo } from "react";
import { Row, Col, Button, Input, Table, notification, Tag } from "antd";
import { BarsOutlined, CheckOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import api from "src/api";
import { useFetch, useValues, useTableScroll } from "src/utils/Hook";
import { createKey } from "src/utils/Helper";
import ContextMenu from "src/components/ContextMenu";
import * as CopyToClipboard from "react-copy-to-clipboard";

const cacheKey = createKey("/domain");

const Domain = ({ user, history }) => {
    const [values, setValues] = useValues({
        pagination: { showSizeChanger: false },
        loading: false,
        dataSource: [],
    });

    const [tableRef, scrollY] = useTableScroll(64);

    const contextMenuController = ContextMenu.useController();

    const [fetchParams, fetch] = useFetch(
        { page: 1, slug: "" },
        (fetchParams) => {
            setValues({ loading: true });
            let filters = [];
            fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
            api.get("/domain", {
                _page: fetchParams.page,
                _perPage: 10,
                _filter: filters?.join(";") || undefined,
                _relations: "user",
            }).then(({ status, data, meta }) => {
                if (status) {
                    setValues({
                        loading: false,
                        dataSource: data,
                        pagination: {
                            showSizeChanger: values.pagination.showSizeChanger,
                            current: meta.pagination.current_page,
                            pageSize: meta.pagination.per_page,
                            total: meta.pagination.total,
                        },
                    });
                } else {
                    setValues({ loading: false, dataSource: [] });
                }
            });
        },
        cacheKey
    );

    const handleSearch = (slug) => {
        fetch({ slug, page: 1 }, true);
    };

    const handleTableChange = (paginations, filters, sorters) => {
        let page = paginations.current;
        fetch({ page });
    };

    const handleCopy = () => {
        notification.success({
            message: "Thông báo",
            description: "Đã sao chép",
        });
    };

    const renderDnsRecord = (record) => {
        let type = record?.recordType;
        if (type === "Txt") {
            return (
                <>
                    <span style={{ fontSize: 12 }}>Type:</span>{" "}
                    <Tag style={{ textTransform: "uppercase" }}>{record?.recordType}</Tag>
                    <span style={{ fontSize: 12 }}>Name:</span>{" "}
                    <CopyToClipboard text={record?.label} onCopy={handleCopy}>
                        <Tag style={{ cursor: "pointer", userSelect: "none" }}>{record?.label}</Tag>
                    </CopyToClipboard>
                    <span style={{ fontSize: 12 }}>Content:</span>{" "}
                    <CopyToClipboard text={record?.text} onCopy={handleCopy}>
                        <Tag style={{ cursor: "pointer", userSelect: "none" }}>{record?.text}</Tag>
                    </CopyToClipboard>
                    <span style={{ fontSize: 12 }}>TTL:</span>{" "}
                    <CopyToClipboard text={record?.ttl} onCopy={handleCopy}>
                        <Tag style={{ cursor: "pointer", userSelect: "none" }}>{record?.ttl}</Tag>
                    </CopyToClipboard>
                </>
            );
        } else if (type === "Mx") {
            return (
                <>
                    <span style={{ fontSize: 12 }}>Type:</span>{" "}
                    <Tag style={{ textTransform: "uppercase" }}>{record?.recordType}</Tag>
                    <span style={{ fontSize: 12 }}>Name:</span>{" "}
                    <CopyToClipboard text={record?.label} onCopy={handleCopy}>
                        <Tag style={{ cursor: "pointer", userSelect: "none" }}>{record?.label}</Tag>
                    </CopyToClipboard>
                    <span style={{ fontSize: 12 }}>Server:</span>{" "}
                    <CopyToClipboard text={record?.mailExchange} onCopy={handleCopy}>
                        <Tag style={{ cursor: "pointer", userSelect: "none" }}>{record?.mailExchange}</Tag>
                    </CopyToClipboard>
                    <span style={{ fontSize: 12 }}>Priority:</span>{" "}
                    <CopyToClipboard text={record?.preference} onCopy={handleCopy}>
                        <Tag style={{ cursor: "pointer", userSelect: "none" }}>{record?.preference}</Tag>
                    </CopyToClipboard>
                    <span style={{ fontSize: 12 }}>TTL:</span>{" "}
                    <CopyToClipboard text={record?.ttl} onCopy={handleCopy}>
                        <Tag style={{ cursor: "pointer", userSelect: "none" }}>{record?.ttl}</Tag>
                    </CopyToClipboard>
                </>
            );
        }
    };

    const columns = useMemo(() => {
        return [
            {
                title: "Tên miền",
                dataIndex: "domain",
                key: "domain",
                width: 220,
            },
            {
                title: "Người tạo",
                dataIndex: ["user", "name"],
                key: "user",
                width: 220,
            },
            {
                title: "Xác minh",
                dataIndex: "verified",
                key: "verified",
                width: 500,
                render: (verified, row) =>
                    verified ? (
                        "Đã xác minh"
                    ) : (
                        <div style={{ marginBottom: -5 }}>
                            <div style={{ marginBottom: 5 }}>
                                <Tag>Chưa xác minh</Tag>
                            </div>
                            <div>
                                {row?.dns_records?.map((record, idx) => (
                                    <div key={idx} style={{ marginBottom: 5 }}>
                                        {renderDnsRecord(record)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ),
            },
        ];
    }, []);

    useEffect(() => {
        fetch({}, true);

        contextMenuController.setItems([
            // {
            //     text: "Cập nhật",
            //     icon: <EditOutlined />,
            //     onClick: (row) => {
            //         history.push(`/domain/${row.id}`);
            //     },
            // },
            {
                condition: (row) => row.verified,
                text: "Quản lý DNS",
                icon: <BarsOutlined />,
                onClick: (row) => {
                    history.push(`/domain/${row.id}/services`);
                },
            },
            {
                condition: (row) => !row.verified,
                text: "Xác minh",
                icon: <CheckOutlined />,
                onClick: (row) => {
                    setValues({ loading: true });
                    api.post(`/domain/${row.id}/verify`)
                        .then(({ status, message }) => {
                            if (status) {
                                notification.success({
                                    message: "Thông báo",
                                    description: message || "Xác minh thành công",
                                });
                                fetch({}, true);
                            } else {
                                notification.warning({
                                    message: "Thông báo",
                                    description: message || "Xác minh không thành công",
                                });
                                setValues({ loading: false });
                            }
                        })
                        .catch(() => {
                            notification.warning({
                                message: "Thông báo",
                                description: "Xác minh không thành công",
                            });
                            setValues({ loading: false });
                        });
                },
            },
            {
                text: "Xóa",
                icon: <DeleteOutlined />,
                confirm: true,
                confirmTitle: "Bạn có chắc muốn xóa?",
                condition: user.hasRole("ADMIN"),
                onConfirm: (row) => {
                    setValues({ loading: true });
                    api.delete(`/domain/${row.id}`)
                        .then(({ status, message }) => {
                            if (status) {
                                notification.success({
                                    message: "Thông báo",
                                    description: message || "Xóa thành công",
                                });
                                fetch({}, true);
                            }
                        })
                        .catch(() => {
                            setValues({ loading: false });
                        });
                },
            },
        ]);
    }, []);

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Row gutter={10} justify="space-between" style={{ paddingBottom: 10 }}>
                        <Col>
                            <Input.Search
                                defaultValue={fetchParams.slug}
                                placeholder="Tìm kiếm ..."
                                style={{ width: 250 }}
                                onSearch={handleSearch}
                                allowClear
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Button
                        onClick={() => history.push("/domain/new")}
                        type="primary"
                        icon={<PlusOutlined />}
                        disabled={
                            (!user.hasRole("ADMIN") &&
                                user.getData("limit_domain") <= (values.pagination.total || 0)) ||
                            values.pagination.total === undefined
                        }
                    >
                        Thêm mới
                    </Button>
                </Col>
            </Row>
            <div ref={tableRef}>
                <Table
                    rowKey="id"
                    loading={values.loading}
                    dataSource={values.dataSource}
                    columns={columns}
                    pagination={values.pagination}
                    onChange={handleTableChange}
                    scroll={{ y: scrollY }}
                    onRow={contextMenuController.onRow}
                />
            </div>
            <ContextMenu controller={contextMenuController} />
        </>
    );
};

export default Domain;

import React, { useEffect, useState } from "react";
import { Layout, Spin } from "antd";
import { Route, Switch } from "react-router-dom";
import { getCurrentVersion, showNewVersionPopup } from "src/utils";
import Config from "@config";
import api from "src/api";
import routes from "src/routes";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Forbidden from "./Forbidden";
import PageNotFound from "./PageNotFound";
import jwtDecode from "jwt-decode";
import User, { setCacheUser } from "src/utils/User";

import "./main.scss";

const Render = ({ component, props = {}, user = undefined }) => {
    return component({ ...props, user });
};

const Main = ({ history }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            api.get("/me").then(({ status, token, data }) => {
                if (status) {
                    let roles = jwtDecode(token);
                    let newUser = new User(roles, data);
                    setCacheUser(newUser);
                    setUser(newUser);
                }
            });
        } else {
            history.replace("/login");
        }

        if (Config.NODE_ENV == "production") {
            getCurrentVersion().then((hash) => {
                localStorage.setItem("hash_version", hash);
                const checkVersionItv = setInterval(() => {
                    getCurrentVersion().then((hash) => {
                        if (localStorage.getItem("hash_version") != hash) {
                            clearInterval(checkVersionItv);
                            showNewVersionPopup();
                        }
                    });
                }, 60000);
            });
        }
    }, []);

    return (
        <>
            {user ? (
                <Layout className="main-container">
                    <Sidebar user={user} />
                    <Layout className="site-layout">
                        <Header history={history} user={user} />
                        <Layout.Content id="main-content" className="site-layout-background">
                            <Switch>
                                {routes.map((item, index) => {
                                    if (user.hasRole(item.roles)) {
                                        return (
                                            <Route
                                                key={index}
                                                path={item.path}
                                                exact={item.exact}
                                                component={(props) => (
                                                    <Render component={item.component} props={props} user={user} />
                                                )}
                                            />
                                        );
                                    } else {
                                        return <Route key={index} path={item.path} component={Forbidden} />;
                                    }
                                })}
                                <Route component={PageNotFound} />
                            </Switch>
                        </Layout.Content>
                    </Layout>
                </Layout>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <Spin />
                </div>
            )}
        </>
    );
};

export default Main;

import React, { useEffect } from "react";
import { Button, Input, Row, Col, Tabs, Select, Form, notification, Checkbox, InputNumber } from "antd";
import api from "src/api";
import CenterArea from "src/components/CenterArea";
import { createKey } from "src/utils/Helper";
import { useValues } from "src/utils/Hook";

const { TabPane } = Tabs;

const Detail = ({ history, match }) => {
    const [form] = Form.useForm();
    const { id } = match.params;

    const [values, setValues] = useValues({
        loading: false,
        roles: [],
        microsoftAdmins: [],
    });

    const onFinish = () => {
        setValues({ loading: true });
        form.validateFields().then(async (values) => {
            let data = {
                active: values.active,
                limit_account: values.limit_account,
                limit_domain: values.limit_domain,
                ms_admin_id: values.ms_admin_id || null,
                name: values.name,
                roles: values.roles || [],
                username: values.username,
                new_password: values.new_password,
            };
            if (id) {
                api.put(`/user/${id}`, data).then(({ status, message }) => {
                    setValues({ loading: false });
                    if (status) {
                        notification.success({
                            message: "Thông báo",
                            description: message || "Lưu thành công",
                        });
                        // handleBack();
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: message || "Lỗi hệ thống",
                        });
                    }
                });
            } else {
                api.post("/user", data).then(({ status, message }) => {
                    setValues({ loading: false });
                    if (status) {
                        let key = createKey("/user");
                        window.sessionStorage.removeItem(key);
                        handleBack();
                    } else {
                        notification.warning({
                            message: "Thông báo",
                            description: message || "Lỗi hệ thống",
                        });
                    }
                });
            }
        });
    };

    const handleBack = () => {
        history.push({
            pathname: `/user`,
        });
    };

    useEffect(() => {
        let promises = [];
        promises.push(api.get(`/user/roles`));
        promises.push(api.get(`/microsoft-admin/options`));
        Promise.all(promises)
            .then(([roleRes, adminRes]) => {
                setValues({
                    roles: roleRes.status ? roleRes.data : [],
                    microsoftAdmins: adminRes.status ? adminRes.data : [],
                });
            })
            .catch(() => {});
        if (id) {
            setValues({ loading: true });
            api.get(`/user/${id}`).then(({ status, data }) => {
                if (status) {
                    form.setFieldsValue({
                        name: data.name,
                        username: data.username,
                        active: data.active,
                        roles: data.roles,
                        limit_domain: data.limit_domain,
                        limit_account: data.limit_account,
                        ms_admin_id: data.ms_admin_id,
                    });
                    setValues({ loading: false });
                }
            });
        } else {
            form.setFieldsValue({
                active: true,
                limit_domain: 0,
                limit_account: 0,
            });
        }
    }, []);

    return (
        <Tabs defaultActiveKey="general">
            <TabPane tab="Thông tin tài khoản" key="general">
                <CenterArea xxl={1350} xl={940} xs="100%" loading={values.loading}>
                    <Form
                        className="small-form"
                        form={form}
                        onFinish={onFinish}
                        labelCol={{ span: 24 }}
                        initialValues={{
                            limit_domain: 0,
                            limit_account: 0,
                        }}
                    >
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Họ tên" name="name">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Tên đăng nhập" name="username">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Mật khẩu mới" name="new_password">
                                    <Input type="password" autoComplete="new-password" placeholder="********" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Kích hoạt" name="active" valuePropName="checked">
                                    <Checkbox>Kích hoạt</Checkbox>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Quyền" name="roles">
                                    <Select mode="multiple" placeholder="Chọn quyền" allowClear>
                                        {values.roles.map((item, idx) => (
                                            <Select.Option key={idx} value={item.id}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item label="Tài khoản microsft" name="ms_admin_id">
                                    <Select placeholder="Chọn tài khoản" allowClear>
                                        {values.microsoftAdmins.map((item, idx) => (
                                            <Select.Option key={idx} value={item.id}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Số lượng tên miền tối đa" name="limit_domain">
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item label="Số lượng tài khoản tối đa" name="limit_account">
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={20}>
                                <Form.Item>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            <Button htmlType="submit" type="primary">
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CenterArea>
            </TabPane>
        </Tabs>
    );
};

export default Detail;

export const STORE_ACTION_SET_TOKEN = "SET_TOKEN";
export const STORE_ACTION_CLEAN_TOKEN = "CLEAN_TOKEN";
export const STORE_ACTION_SET_AUTHED = "SET_AUTHED";

export const setToken = (token) => ({
    type: STORE_ACTION_SET_TOKEN,
    payload: { token },
});

export const cleanToken = () => ({
    type: STORE_ACTION_CLEAN_TOKEN,
    payload: {},
});

export const setAuthed = (roles, data) => ({
    type: STORE_ACTION_SET_AUTHED,
    payload: { roles, data },
});

import { useState } from "react";
import { Modal, Button } from "antd";
import { ImportOutlined } from "@ant-design/icons";
import CustomTabs from "./CustomTabs";
import "./index.scss";

export default function CustomModal({ triggerBtnFullWidth = false, disabled = false }) {
    const [open, setOpen] = useState(false);

    const handleToggleModal = () => {
        setOpen(!open);
    };

    return (
        <>
            <Button
                block={triggerBtnFullWidth}
                onClick={handleToggleModal}
                type="primary"
                icon={<ImportOutlined />}
                disabled={disabled}
            >
                Import
            </Button>
            <Modal
                width="70%"
                open={open}
                title="Import"
                onCancel={handleToggleModal}
                okButtonProps={{ style: { display: "none" } }}
            >
                {/* Using key to trigger rerender Tabs on Modal without placing FileList state in Modal */}
                <CustomTabs key={Date.now()} />
            </Modal>
        </>
    );
}
